<template>
  <div>
    <div v-show="displayApp">
      <RouterView :key="selectedTeamPreference" />
      <UniversalSearch
        v-show="searchOpen"
        @close="hideSearch"
      />
      <div
        v-show="playerOpen"
        class="player-popout-target-container"
      >
        <PortalTarget :name="POPOUT_PLAYER_TARGET" />
      </div>
      <PlayerPopout @close="playerHide" />
    </div>
  </div>
</template>

<script>
import { Browser } from "@capacitor/browser";
import { Keyboard } from "@capacitor/keyboard";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";
import { storeToRefs } from "pinia";
import { PortalTarget } from "portal-vue";
import { Platform } from "quasar";

import PlayerPopout from "shared/components/players/PlayerPopout.vue";
import UniversalSearch from "shared/components/search/UniversalSearch.vue";
import useTeamPicker from "shared/composables/useTeamPicker";
import { POPOUT_PLAYER_TARGET } from "shared/constants";
import { getCssVariableValue } from "shared/helpers/dom";
import MediaRequestsPoller from "shared/services/polling/MediaRequestsPoller";
import MentionCountsPoller from "shared/services/polling/MentionCountsPoller";
import TranscriptsPoller from "shared/services/polling/TranscriptsPoller";
import { useAlertsStore } from "shared/stores/alerts";
import { useGlobalStore } from "shared/stores/global";
import { useMentionCountsStore } from "shared/stores/mentionCounts";
import { useStreamsStore } from "shared/stores/streams";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";
import { useUniversalSearchStore } from "shared/stores/universalSearch";
import { useUserStore } from "shared/stores/user";

import AlertsPoller from "src/services/AlertsPoller";
import {
  GoogleLoginPoller,
  refreshGoogleLogin,
  signOutGoogle,
} from "src/services/googleSso";
import {
  MicrosoftLoginPoller,
  refreshMicrosoftLogin,
  signOutMicrosoft,
} from "src/services/microsoftSso";
import PushNotificationService from "src/services/PushNotificationService";
import UniversalLinksService from "src/services/universalLinks";
import { useMobileStore } from "src/stores/mobile";

export default {
  name: "App",
  components: {
    PortalTarget,
    PlayerPopout,
    UniversalSearch,
  },
  setup() {
    const alertsStore = useAlertsStore();
    const { alertCount } = storeToRefs(alertsStore);
    const { fetchAlertCount } = alertsStore;

    const { fetchMentionCount } = useMentionCountsStore();

    const streamsStore = useStreamsStore();
    const { getStreams } = streamsStore;

    const universalPlayerStore = useUniversalPlayerStore();
    const { playerOpen } = storeToRefs(universalPlayerStore);
    const { playerHide } = universalPlayerStore;

    const universalSearchStore = useUniversalSearchStore();
    const { hideSearch } = universalSearchStore;
    const { searchOpen } = storeToRefs(universalSearchStore);

    const globalStore = useGlobalStore();
    const { globalDataLoaded, globalDataError } = storeToRefs(globalStore);
    const { getAppData } = globalStore;

    const userStore = useUserStore();

    const { isLoggedIn, enforceTwoFactor, identityProvider } =
      storeToRefs(userStore);

    const { hydrateInitialLoginState } = userStore;

    const mobileStore = useMobileStore();

    const { updateKeyboardOpen, setKeyboardHeight, setOrientationAngle } =
      mobileStore;

    const { selectedTeamPreference } = useTeamPicker();

    globalStore.$onAction(({ name }) => {
      if (name === "setGlobalDataLoaded") {
        SplashScreen.hide();
      }
    });

    return {
      alertCount,
      fetchAlertCount,
      fetchMentionCount,
      getStreams,

      playerOpen,
      playerHide,

      hideSearch,
      searchOpen,

      globalDataLoaded,
      globalDataError,
      getAppData,

      isLoggedIn,
      enforceTwoFactor,
      identityProvider,
      hydrateInitialLoginState,

      updateKeyboardOpen,
      setKeyboardHeight,
      setOrientationAngle,

      selectedTeamPreference,
    };
  },
  data() {
    return {
      POPOUT_PLAYER_TARGET,
    };
  },
  computed: {
    displayApp() {
      return (
        !this.isLoggedIn ||
        this.enforceTwoFactor ||
        (this.globalDataLoaded && !this.globalDataError)
      );
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (Platform.is.capacitor) {
          let statusBarColor = getCssVariableValue("--s-menu-default");

          if (this.globalDataLoaded) {
            statusBarColor = this.$route.meta.statusBarColor || "#E6EBF0";
          }

          await StatusBar.setBackgroundColor({ color: statusBarColor }).catch(
            () => {}
          );

          await StatusBar.setStyle({
            style: Style[this.$route.meta.statusBarStyle || "Default"],
          });
        }
      },
    },
    globalDataLoaded() {
      if (Platform.is.capacitor && this.globalDataLoaded) {
        PushNotificationService.processCachedNotification();
        PushNotificationService.start();
        UniversalLinksService.start(this.$router);
      }
    },
    isLoggedIn: {
      immediate: true,
      handler(isLoggedIn, wasLoggedIn) {
        if (isLoggedIn && wasLoggedIn === false) {
          SplashScreen.show({ autoHide: false });
        }

        if (wasLoggedIn && !isLoggedIn) {
          signOutMicrosoft();
          signOutGoogle();
        }

        if (!this.isLoggedIn && Platform.is.capacitor) {
          PushNotificationService.stop();
          UniversalLinksService.stop();
        }

        this.togglePolling(this.isLoggedIn);
      },
    },
  },
  async mounted() {
    if (Platform.is.capacitor) {
      await SplashScreen.show({ autoHide: false });
      this.initCapacitorPlugins();
    }

    // Setting the initial orientation angle
    const angle = Platform.is.ios
      ? window.orientation
      : window.screen.orientation.angle;

    this.setOrientationAngle(angle);
    // It is written outside of capacitor, in case we want to use the event not just for mobile
    // browser app for mobile
    window.addEventListener("orientationchange", this.updateOrientation);

    // If user is logged in, we load data needed by most of the pages
    // Otherwise we will load data after the login
    // Until global data are loaded,
    // a spinner is displayed on layouts/default instead of the router view
    if (this.isLoggedIn) {
      if (this.identityProvider === "microsoft") {
        try {
          await refreshMicrosoftLogin();
          MicrosoftLoginPoller.init();
        } catch {
          return;
        }
      }

      if (this.identityProvider === "google") {
        try {
          await refreshGoogleLogin();
          GoogleLoginPoller.init();
        } catch {
          return;
        }
      }

      await this.getAppData();
    }
  },
  methods: {
    async initCapacitorPlugins() {
      await StatusBar.setOverlaysWebView({ overlay: false }).catch(() => {});

      // Virtual keyboard is shown (used for modals only)
      Keyboard.addListener("keyboardWillShow", (event) => {
        this.updateKeyboardOpen(true);
        this.setKeyboardHeight(event.keyboardHeight);
      });

      Keyboard.addListener("keyboardWillHide", () => {
        this.updateKeyboardOpen(false);
      });

      Keyboard.setAccessoryBarVisible({ isVisible: false }).catch(() => {});

      window.open = (url) => Browser.open({ url });
    },
    updateOrientation() {
      // window.screen not supported in safari so is necessary to use window.orientation
      const angle = Platform.is.ios
        ? window.orientation
        : window.screen.orientation.angle;

      this.setOrientationAngle(angle);
    },
    togglePolling(startPolling) {
      if (startPolling) {
        MentionCountsPoller.init();
        AlertsPoller.init();
        MediaRequestsPoller.init();
        TranscriptsPoller.init();
      } else {
        MentionCountsPoller.stop();
        AlertsPoller.stop();
        MediaRequestsPoller.stop();
        TranscriptsPoller.stop();
        MicrosoftLoginPoller.stop();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.player-bar-open > .streem-modal-mask) {
  height: calc(100vh - 56px) !important;
}

.player-popout-target-container {
  position: fixed;
  bottom: 0;
  width: 347px;
}
</style>
