import { SocialLogin } from "@capgo/capacitor-social-login";
import { initializeApp } from "@firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  setPersistence,
  signInWithCredential,
  signOut,
} from "@firebase/auth/cordova";
import { toRaw } from "vue";

import { HOUR_IN_MILLISECONDS } from "shared/constants";
import { getAppConfig } from "shared/helpers/useAppConfig";
import Poller from "shared/services/polling/Poller";
import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

let initialized = false;
let googleProvider;
let firebaseApp;
let firebaseAuth;

const ready = new Promise((resolve) => {
  document.addEventListener("deviceReady", resolve);
});

async function initializeFirebase() {
  await ready;

  if (!googleProvider) {
    googleProvider = new GoogleAuthProvider();
    firebaseApp = initializeApp(useGlobalStore().appConfig.firebaseAppConfig);
    firebaseAuth = getAuth(firebaseApp);
    await setPersistence(firebaseAuth, indexedDBLocalPersistence);
  }
}

async function initializeGoogleAuth() {
  if (initialized) return;

  const config = getAppConfig();
  const { google } = toRaw(config.value.socialLogin);

  await SocialLogin.initialize({ google });

  initialized = true;
}

export const loginWithGoogle = async () => {
  initializeGoogleAuth();
  await initializeFirebase();

  return new Promise((resolve, reject) => {
    SocialLogin.login({
      provider: "google",
      options: {
        scopes: ["email", "profile"],
      },
    })
      .then(async (response) => {
        try {
          const {
            result: {
              idToken: oAuthIdToken,
              profile: { email },
            },
          } = response;

          const credential = GoogleAuthProvider.credential(oAuthIdToken);
          const { user } = await signInWithCredential(firebaseAuth, credential);
          const idToken = await user.getIdToken();

          resolve({ email, idToken });
        } catch (error) {
          reject(error);
        }
      })
      .catch(reject);
  });
};

export const refreshGoogleLogin = async () => {
  initializeGoogleAuth();
  await initializeFirebase();

  try {
    const idToken = await firebaseAuth.currentUser.getIdToken(true);

    await useUserStore().refreshGoogleSsoCallback({
      idToken,
      client: "web",
    });
  } catch {
    signOut(firebaseAuth);
    useUserStore().logout();
  }
};

export const signOutGoogle = async () => {
  initializeGoogleAuth();
  await SocialLogin.logout({ provider: "google" }).catch(() => {});

  if (firebaseAuth?.currentUser) signOut(firebaseAuth);
};

export const GoogleLoginPoller = new Poller({
  callback: refreshGoogleLogin,
  interval: HOUR_IN_MILLISECONDS,
});
