import { storeToRefs } from "pinia";
import type { Ref } from "vue";

import type { AppConfig } from "shared/services/appConfig/types";
import { useGlobalStore } from "shared/stores/global";

export const getAppConfig = (): Ref<AppConfig> => {
  const globalStore = useGlobalStore();

  return storeToRefs(globalStore).appConfig as Ref<AppConfig>;
};

export default getAppConfig;
